import React, { useEffect, useState } from "react";
import { useGetDraftListQuery } from "../../redux/storeApis";
import DraftCard from "../../components/shared/mailCard/DraftCard";
import useComposeModel from "../../lib/customHooks/useComposeModel";
import { EmptyLoader, SimpleLoader } from "../../components/loader/Loaders";
import { useSearchManager } from "../../lib/customHooks/useSearchManager";
import { useSelector } from "react-redux";
import { selectComposeModel, selectDraftData, setIsEmailSavedToDraft } from "../../redux/ComposeModelReducer";
import { selectIsLoading } from "../../redux/LoadingReducer";
import { useDispatch } from "react-redux";
import DZSearchBar from "../../components/shared/searchbar/DZSearchbar";

const Draft = () => {

  const dispatch = useDispatch();

  const { data: allDrafts, isLoading: isLoadingAllDrafts, isFetching: isFetchingDraftMails, } = useGetDraftListQuery();

  const { data, handleDraftOpenComposeModel } = useComposeModel({ isFetchingDraftMails });
  const { fnOnSearchInputChange, fnOnSearch, fnOnEnter, fnFilterDrafts } = useSearchManager();
  const isShowLoader = useSelector(selectIsLoading);
  const composeModel = useSelector(selectComposeModel);
  const draftData = useSelector(selectDraftData);

  const [selectedDraftId, setSelectedDraftId] = useState(null);

  const draftEmails = allDrafts?.data?.drafts;

  const filteredDrafts = fnFilterDrafts(draftEmails);
  const isExistDrafts = filteredDrafts?.length > 0;

  useEffect(() => { if(composeModel || !draftData) { setSelectedDraftId(null) } }, [composeModel, draftData]);

  const fnOnClickDraft =async(draft)=> {
    
    if(selectedDraftId !== draft?.id) { setSelectedDraftId(draft?.id); } 
    else { setSelectedDraftId(null) }
    
    dispatch(setIsEmailSavedToDraft(false));
    handleDraftOpenComposeModel({ id: draft?.id, receiver: [...draft?.receiver], subject: draft?.subject ?? "", body: draft?.body ?? "", }, false);
  };

  return (
    <div className="flex flex-col items-center w-full gap-[16px] overflow-y-scroll overflow-x-hidden max-sm:pb-[124px] pb-[60px]  h-[100dvh]">

      <DZSearchBar
        onChange={(event) => fnOnSearchInputChange(event)}
        onKeyDown={fnOnEnter}
        onClick={fnOnSearch}
      />

      {(isLoadingAllDrafts || isShowLoader) ? <SimpleLoader /> :
        isExistDrafts ? filteredDrafts?.map((draft) => {
          return (
            <span className="w-full" onClick={() => fnOnClickDraft(draft)}  >
              <DraftCard key={draft?.id} email={draft} selected={draft?.id == selectedDraftId} setSelectedDraftId={setSelectedDraftId} />
            </span>
          )
        }) : <EmptyLoader />
      }
    </div>
  );
};
export default Draft;