import React, { useEffect, useRef, useState } from "react";
import useDownloadFileFolder from "../../../lib/useDownloadFileFolder";
import useHandleDriveStar from "../../../lib/useHandleDriveStar";
import { useDownloadManager } from "../../../lib/customHooks/useDownloadManager";
import useDeleteFileFolder from "../../../lib/useDeleteFileFolder";
import { useDispatch } from "react-redux";
import useKeyPress from "../../../lib/useKeyPress";
import useOutsideClick from "../../../lib/useOutsideClick";
import { BrokenIcon, DeleteIcon, DownloadIcon, EditIcon, RestoreIcon, StarIcon } from "../../../icons/generalIcons/Icons";
import DZText from "../text/DZText";
import { setSourceFile } from "../../../redux/MoveReducer";
import { hideModal, selectConditionalModal, showModal } from "../../../redux/ConditionalModelReducer";
import { useSelector } from "react-redux";

function DriveCardModal({
  setShowDropDown,
  showDropDown,
  cardType,
  pathName,
  trash,
  folder,
  xPos,
  yPos,
  isSelected,
  dropDownPopupRef,
  dropdownRef,
  setShowMoveModal,
  showMoveModal,
  setShowRenameModal
}) {

  const dispatch = useDispatch();
  const { handleDelete, handleRestoreFile, isDeleteLoading } = useDeleteFileFolder();
  const showModalPreviousData = useSelector(selectConditionalModal);

  const cleanedPathName = pathName?.replace(/\/{2,}/g, "/");
  const clearedPathName = cleanedPathName?.replace("/drive/folders", "")?.replace(/^\//, "");

  const { handleFavorites, isFavLoading, isAlreadyFav } = useHandleDriveStar(clearedPathName, cardType);
  const { handleDownload, isDownloading } = useDownloadManager(pathName);

  useEffect(() => {
    dispatch(setSourceFile(cleanedPathName));
  }, [dispatch, cleanedPathName]);

  useKeyPress("Escape", () => setShowMoveModal(false));

  useOutsideClick(dropdownRef, () => setShowDropDown(false));

  useEffect(() => {
    if (dropDownPopupRef.current) {
      const dropdownHeight = dropDownPopupRef.current.offsetHeight;
      const dropdownTop = dropDownPopupRef.current.getBoundingClientRect().top;
      const viewportHeight = window.innerHeight - dropdownHeight - 60;
      dropDownPopupRef.current.style.top =
        viewportHeight - dropdownTop >= dropdownHeight ? "90%" : "-220%";
    }
  }, [showDropDown]);

  const onClickDelete = () => {
    dispatch(
      showModal({
        isVisible: true,
        title: trash ? "Permanent Delete" : "Move to Trash!",
        message: trash ? "Are you sure you want to remove permanently?" : "Are you sure you want to move this to Trash?",
        okCallback: () => {
          handleDelete(cleanedPathName);
          dispatch(showModal({ isLoading: true }));
        },
        cancelCallback: () => {
          dispatch(hideModal());
        },
        isLoading: false
      })
    );
  };

  return (
   
      <div
        style={{ top: yPos, left: xPos }}
        ref={dropDownPopupRef}
        className={`${showDropDown
            ? "animation-DriveCardDropDownOpen"
            : "animation-DriveCardDropDownClose"
          } p-[12px] flex flex-col gap-[10px] rounded-[10px] bg-DBE2EF absolute ${!(yPos || xPos) && "right-0"
          } z-[1] shadow w-[160px]`}
      >
        <div
          className="flex gap-[4px] items-center cursor-pointer group"
          onClick={(e) => {
            e.stopPropagation();
            onClickDelete();
          }}
        >
          <DeleteIcon className="cursor-pointer" />
          <DZText
            children={trash ? "Delete" : "Move to Trash"}
            className="cursor-pointer text-112D4E transition group-hover:translate-x-2"
          />
        </div>
        {trash && (
          <div
            onClick={() => handleRestoreFile(folder?.id)}
            className="flex gap-[4px] items-center cursor-pointer group"
          >
            <RestoreIcon className="cursor-pointer" />
            <DZText
              children="Restore"
              className="cursor-pointer text-112D4E transition group-hover:translate-x-2"
            />
          </div>
        )}
        {!trash && (
          <div
            className="flex gap-[4px] items-center cursor-pointer group"
            onClick={(e) => {
              e.stopPropagation();
              setShowMoveModal(true);
            }}
          >
            <BrokenIcon className="cursor-pointer" />
            <DZText
              children="Move"
              className="cursor-pointer text-112D4E transition group-hover:translate-x-2"
            />
          </div>
        )}
        {!trash && (
          <div
            className="flex gap-[4px] items-center cursor-pointer group"
            onClick={() => handleDownload(folder)}
          >
            <DownloadIcon className="cursor-pointer" />
            <DZText
              children="Download"
              className="cursor-pointer text-112D4E transition group-hover:translate-x-2"
            />
          </div>
        )}

        {/* <div
      className="flex gap-[4px] items-center cursor-pointer group"
      onClick={(e) => {
        e.stopPropagation();
        downloadFile(clearedPathName);
      }}
    >
      <DownloadIcon className="cursor-pointer" />
      <DZText
        children="Download"
        className="cursor-pointer text-112D4E transition group-hover:translate-x-2"
      />
    </div> */}

        {!trash && (
          <div
            className={`flex gap-[4px] items-center cursor-pointer group transition ${isFavLoading ? "" : ""
              }`}
            onClick={(e) => {
              e.stopPropagation();
              handleFavorites();
            }}
          >
            <StarIcon
              className={`cursor-pointer ${isFavLoading ? "vibrate-anime" : ""}`}
              iconColor="#112D4E"
              bgColor={isAlreadyFav ? "#112D4E" : ""}
            />
            <DZText
              children="Favorites"
              className="cursor-pointer text-112D4E transition group-hover:translate-x-2"
            />
          </div>
        )}
        {/* {!trash && (
      <div className="flex gap-[4px] items-center cursor-pointer group">
        <BrokenIcon className="cursor-pointer" />
        <DZText
          children="Share"
          className="cursor-pointer text-112D4E transition group-hover:translate-x-2"
        />
      </div>
    )} */}
        {cardType == "file" && !trash && (
          <div
            className="flex gap-[4px] items-center cursor-pointer group"
            onClick={(e) => {
              e.stopPropagation();
              setShowRenameModal(true);
            }}
          >
            <EditIcon className="cursor-pointer" />
            <DZText
              children="Rename"
              className="cursor-pointer text-112D4E transition group-hover:translate-x-2"
            />
          </div>
        )}
      </div>
  );
}

export default DriveCardModal;
