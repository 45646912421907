import React from 'react'

const DZTextArea = ({ 
    id,
    name,
    type,
    placeholder = 'Note (optional)' , 
    value,
    onChange,
    onKeyDown,
    className = null,
    disabled = false, }) => {
  return (
    <>
    
    <textarea 
        placeholder={placeholder}
        className={`${className} ${
            disabled ? "bg-white" : ""
          } placeholder:capitalize h-[110px] resize-none text-3F72AF px-[20px] py-[14px] cursor-text outline-none rounded-[10px]`} 
        id={id}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        disabled={disabled}
    />
    </>
  )
}

export default DZTextArea