import React, { useEffect, useState } from "react";
import FolderIcon from "../../../icons/cardIcons/FolderIcon";
import { CloudIcon } from "../../../icons/generalIcons/Icons";
import DZText from "../text/DZText";
import TimeDisplay from "../../ui/error/TimeDisplay";
import { useNavigate } from "react-router-dom";
import DriveCardDropDown from "../dropDowns/DriveCardDropDown";
import { Draggable } from "react-beautiful-dnd";
import useFindFileType from "../../../lib/customHooks/useFIndFileType";
import { useDispatch } from "react-redux";
import {
  selectSelectedFilePath,
  setSelectedFilePath,
} from "../../../redux/OperationalBarReducer";
import { useSelector } from "react-redux";
import Image from "../image/Image";
import { selectIsCardSquare } from "../../../redux/CardGridReducer";
import { Config } from "../../../constants/Index";
import FileIcon from "../../../icons/cardIcons/FileIcon";
import VideoIcon from "../../../icons/cardIcons/VideoIcon";
import useKeyPress from "../../../lib/useKeyPress";
import useDeleteFileFolder from "../../../lib/useDeleteFileFolder";
const DriveCard = ({
  folder,
  cardType,
  pathName,
  index,
  offDragDrop,
  trash,
  key,
  name,
  setShowMoveModal,
  showMoveModal,
  onDoubleClick = false,
  disableClick
}) => {
  const pathWithName = `${pathName || ""}${pathName ? "/" : ""}${folder?.name}`;
  const navigate = useNavigate();
  const { handleDelete, handleRestoreFile, isDeleteLoading } =
    useDeleteFileFolder();
  const dispatch = useDispatch();
  const [showDropDown, setShowDropDown] = useState(false);
  const { isPdf, isImg, isVideo, isAudio, findFileType } = useFindFileType();
  const fileFolderPath = useSelector(selectSelectedFilePath);
  const isCardSquare = useSelector(selectIsCardSquare);
  const isSelected = fileFolderPath?.includes(pathWithName);
  // const [xPos, setXPos] = useState()
  // const [yPos, setYPos] = useState()
  useEffect(() => {
    findFileType(folder?.name);
  }, [folder?.name]);

  const handleNavigate = () => {
    const url = `/drive/folders/${pathName}/${folder?.name}`;
    const navigateUrl = url.replace(/\/{2,}/g, "/");
    navigate(navigateUrl);
  };

  const handleClick = () => {
    if (isSelected) {
      const updatedFilePath = fileFolderPath?.filter(
        (path) => path != pathWithName
      );
      dispatch(setSelectedFilePath(updatedFilePath));
    } else {
      const updatedFilePath = [...fileFolderPath, pathWithName];
      dispatch(setSelectedFilePath(updatedFilePath));
    }
  };

  const handleDoubleClick = () => {
    dispatch(setSelectedFilePath([]));
    if (cardType !== "file") {
      handleNavigate();
    } else {
      return null;
    }
  };

  const handleRightClick = (event) => {
    event.preventDefault();
    // setXPos(event.pageX);
    // setYPos(event.pageY);
    setShowDropDown(true)
  };
  const draggableId = pathWithName.replace("/drive/folders", "").replace(/\/{2,}/g, "/")
  return (
    <>
      {<span key={key} onClick={disableClick ? undefined : handleClick}>
        {offDragDrop ? (
          <>
            {isCardSquare ? (
              <div
                key={key}
                onContextMenu={handleRightClick}
                onDoubleClick={onDoubleClick ? onDoubleClick : handleDoubleClick}
                className={` cursor-pointer flex items-center flex-col gap-[20px] px-[12px] w-full pb-[12px] pt-[12px] rounded-[10px]
                 ${disableClick ?(isSelected ?  "bg-DBE2EF" : "bg-F9F7F7") : "bg-F9F7F7" } `}
              >
                <FileOrFolderIcon
                  folder={folder}
                  cardType={cardType}
                  isImg={isImg}
                  isPdf={isPdf}
                  isVideo={isVideo}
                  isAudio={isAudio}
                  key={key}
                  pathName={pathName}
                />
                <div className="flex item-center justify-between w-full items-center relative gap-[10px] cursor-pointer">
                  <div className="flex flex-col gap-[4px] cursor-pointer">
                    <DZText
                      children={folder?.file_name}
                      className={"text-112D4E cursor-pointer truncate max-w-[140px] select-none"}
                    />
                    {folder?.created_at && (
                      <div className="flex items-center gap-[4px] cursor-pointer">
                        <CloudIcon className={"cursor-pointer"} />
                        <DZText
                          children={
                            <TimeDisplay timestamp={folder?.created_at || ""} />
                          }
                          className={"text-112D4E cursor-pointer"}
                        />
                      </div>
                    )}
                  </div>
                  <DriveCardDropDown
                    setShowDropDown={setShowDropDown}
                    showDropDown={showDropDown}
                    cardType={cardType}
                    pathName={pathWithName}
                    folderName={folder?.file_name}
                    trash={trash}
                    folder={folder}
                    isSelected={isSelected}
                  />
                </div>
              </div>
            ) : (
              <div
                onDoubleClick={onDoubleClick ? onDoubleClick : handleDoubleClick}
                onContextMenu={handleRightClick}
                className={`flex items-center gap justify-between w-full gap-[10px] px-[20px] pl-[6px] py-[0px] bg-F9F7F7 rounded-[10px] cursor-pointer ${isSelected ? " bg-DBE2EF " : " bg-F9F7F7 "
                  }`}
              >
                <FileOrFolderIcon
                  folder={folder}
                  cardType={cardType}
                  isImg={isImg}
                  isPdf={isPdf}
                  isVideo={isVideo}
                  isAudio={isAudio}
                  key={key}
                  pathName={pathName}
                />
                <div className="flex items-center w-full justify-between cursor-pointer">
                  <div className="flex items-center gap-[20px] cursor-pointer">
                    <DZText
                      children={folder?.name}
                      className={"text-112D4E  w-[200px] truncate cursor-pointer select-none"}
                    />
                    {folder?.created_at && (
                      <div className="flex items-center gap-[4px] cursor-pointer">
                        <CloudIcon className={"cursor-pointer"} />
                        <DZText
                          children={
                            <TimeDisplay timestamp={folder?.created_at || ""} />
                          }
                          className={"text-112D4E cursor-pointer"}
                        />
                      </div>
                    )}
                  </div>
                  <DriveCardDropDown
                    setShowDropDown={setShowDropDown}
                    showDropDown={showDropDown}
                    cardType={cardType}
                    pathName={pathWithName}
                    folderName={folder?.name}
                    trash={trash}
                    folder={folder}
                    isSelected={isSelected}
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          <Draggable draggableId={draggableId} index={index}>
            {(provided) => (
              <span
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
              >
                {isCardSquare ? (
                  <div
                    onDoubleClick={onDoubleClick ? onDoubleClick : handleDoubleClick}
                    onContextMenu={handleRightClick}
                    className={`cursor-pointer flex items-center flex-col gap-[20px] px-[12px] w-full pb-[12px] pt-[12px] rounded-[10px] ${isSelected ? " bg-DBE2EF " : " bg-F9F7F7 "
                      }`}
                  >
                    <FileOrFolderIcon
                      folder={folder}
                      cardType={cardType}
                      isImg={isImg}
                      isPdf={isPdf}
                      isVideo={isVideo}
                      isAudio={isAudio}
                      key={key}
                      pathName={pathName}
                    />
                    <div className={`${"flex item-center justify-between w-full items-center relative cursor-pointer"}`}>
                      <div className="flex flex-col gap-[4px] cursor-pointer">
                        <DZText
                          children={folder?.name?.split('/').pop()}
                          className={
                            "text-112D4E truncate max-w-[140px] cursor-pointer select-none"
                          }
                        />
                        {folder?.created_at && (
                          <div className="flex items-center gap-[4px] cursor-pointer">
                            <CloudIcon className={"cursor-pointer"} />
                            <DZText
                              children={
                                <TimeDisplay
                                  timestamp={folder?.created_at || ""}
                                />
                              }
                              className={"text-112D4E cursor-pointer"}
                            />
                          </div>
                        )}
                      </div>
                      <DriveCardDropDown
                        setShowDropDown={setShowDropDown}
                        showDropDown={showDropDown}
                        cardType={cardType}
                        pathName={pathWithName}
                        folderName={folder?.name}
                        trash={trash}
                        folder={folder}
                        isSelected={isSelected}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    onDoubleClick={onDoubleClick ? onDoubleClick : handleDoubleClick}
                    onContextMenu={handleRightClick}
                    className={`flex items-center gap justify-between w-full gap-[10px] px-[20px] pl-[6px] py-[0px] bg-F9F7F7 rounded-[10px] relative cursor-pointer ${isSelected ? " bg-DBE2EF " : " bg-F9F7F7 "
                      }`}
                  >
                    <FileOrFolderIcon
                      folder={folder}
                      cardType={cardType}
                      isImg={isImg}
                      isPdf={isPdf}
                      isVideo={isVideo}
                      isAudio={isAudio}
                      key={key}
                    />
                    <div className="flex items-center w-full justify-between cursor-pointer">
                      <div className="flex items-center gap-[20px] cursor-pointer">
                        <DZText
                          children={folder?.name}
                          className={"text-112D4E cursor-text w-[200px] truncate select-none"}
                        />
                        {folder?.created_at && (
                          <div className="flex items-center gap-[4px] cursor-pointer">
                            <CloudIcon className={"cursor-pointer"} />
                            <DZText
                              children={
                                <TimeDisplay
                                  timestamp={folder?.created_at || ""}
                                />
                              }
                              className={"text-112D4E cursor-pointer "}
                            />
                          </div>
                        )}
                      </div>
                      <DriveCardDropDown
                        setShowDropDown={setShowDropDown}
                        showDropDown={showDropDown}
                        cardType={cardType}
                        pathName={pathWithName}
                        folderName={folder?.name}
                        trash={trash}
                        folder={folder}
                        isSelected={isSelected}
                      />
                    </div>
                  </div>
                )}
              </span>
            )}
          </Draggable>
        )}
      </span>}
    </>
  );
};
export default DriveCard;

const FileOrFolderIcon = ({ folder, cardType, isImg, isPdf, isVideo, isAudio, key, pathName, }) => {

  const getImageUrl = (baseProductUrl, pathName, folderName) => {
    if (/^https?:\/\//i.test(pathName)) {
      return pathName;
    } else {
      return `${baseProductUrl}/${pathName || ""}/${folderName}`;
    }
  };
  const url = getImageUrl(Config.baseProductUrl, pathName, folder?.name);
  const cleanedUrl = url.replace(/\/{2,}/g, "/").replace(":/", "://");
  if (isImg) {
    return (
      <Image
        src={cleanedUrl}
        className="object-cover w-[100%] h-[80px] rounded-[10px] cursor-pointer"
        alt={folder?.name}
        loading="lazy"
      />
    );
  } else {
    return (
      <FindIcon
        className={"cursor-pointer select-none"}
        name={
          cardType == "folder"
            ? "folder"
            : isPdf
              ? "file"
              : isVideo
                ? "video"
                : isAudio
                  ? "music"
                  : "file"
        }
      />
    );
  }
};
function FindIcon({ name, className }) {
  return (
    <>
      {name == "folder" ? (
        <FolderIcon className={className} />
      ) : name == "video" ? (
        <VideoIcon className={className} />
      ) : name == "photo" ? (
        <PhotoIcon className={className} />
      ) : name == "music" ? (
        <MusicIcon className={className} />
      ) : name == "file" ? (
        <FileIcon className={className} />
      ) : null}
    </>
  );
}