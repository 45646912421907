import React from 'react'
import { Tooltip } from '@mui/material';

import { StarIcon, ClockIcon, DeleteIcon } from '../../../icons/generalIcons/Icons';

import CheckInput from '../checkInput/CheckInput';
import DZText from '../text/DZText';
import { Config } from '../../../constants/Index';

const MobileMailCard = ({ email, isStarred, active, toggleStarred, toggleActive, toggleSelected, selected, fnClickOnDeleteIcon }) => {

  return (

    <div className={` ${selected ? '!bg-[#86BCFC30]' : ''} flex flex-col items-center px-[16px] py-[12px] bg-F9F7F7 gap-[24px] w-full rounded-[10px] sm:hidden`}>

      <div className="flex items-center justify-between w-full">

        <div className="flex items-center gap-[12px]">

          {/* <Tooltip arrow title='Click to star it (you can see it in the starred page)'>

            <span>
              <StarIcon
                iconColor='#3F72AF'
                pointer
                bgColor={`${isStarred ? '#3F72AF' : ''}`}
                onClick={toggleStarred}
              />
            </span>

          </Tooltip> */}

          <Tooltip arrow title='Click to select it'>

            <span onClick={toggleActive}>
              <CheckInput active={active} />
            </span>

          </Tooltip>

        </div>

        <div className="flex items-center gap-[8px]" onClick={toggleSelected} >

          <ClockIcon iconColor='#112D4E' />

          <DZText
            children={Config.fnConvertEmailDate(email?.created_at)}
            small
            className={'text-nowrap text-112D4E cursor-text'}
          />

          {active && <DeleteIcon onClick={(e) => { e.stopPropagation(); fnClickOnDeleteIcon() }} />}

        </div>

      </div>

      <div className="flex flex-col gap-[4px] w-full" onClick={toggleSelected} >
        
        <div className="flex max-w-full w-full overflow-hidden">
          <div className='truncate'>
            {email?.receiver?.length > 0 &&
              email?.receiver?.map((receiver, index, array) => {
                const isShowComma = index != (array?.length - 1);
                return (
                  <DZText small className={"text-112D4E cursor-pointer pr-1"}>
                    {`${receiver}${isShowComma ? ',' : ''}` ?? ""}
                  </DZText>
                )
              })
            }
          </div>
        </div>

        <DZText
          children={email?.subject || 'No Subject'}
          small
          className={'text-112D4E cursor-text w-fit'}
        />

      </div>

    </div >
  )
}

export default MobileMailCard