import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectComposeModel, selectDefaultToMail, selectDraftData, selectedEmailSavedToDraft, selectedEmptyDraftData, selectedIsDraftUpdateSelector, selectFullComposeModel, selectOpenComposeModel, setComposeModel, setDefaultToMail,
  setDraftData, setFullComposeModel, setIsDraftUpdate, setIsEmailSavedToDraft, setOpenComposeModel
} from "../../redux/ComposeModelReducer";
import { useState } from "react";
import { useCreateDraftMutation, useDeleteDraftMutation, useSendEmailMutation, useUpdateDraftMutation, } from "../../redux/storeApis";
import { useEffect } from "react";
import { useSnackBarManager } from "./useSnackBarManager";
import { useLocation } from "react-router-dom";

function useComposeModel({ isFetchingDraftMails = false, } = {}) {

  const emailEmptyData = { receiver: [], subject: "", body: "", attachments : [] };

  const { pathname } = useLocation();
  const [data, setData] = useState(emailEmptyData);

  const [addDraft] = useCreateDraftMutation();
  const [updateDraft] = useUpdateDraftMutation();
  const [deleteDraft, { isLoading: isLoadingDeleteDraft }] = useDeleteDraftMutation();
  const [sendMail, { isLoading: isLoadingSendMail }] = useSendEmailMutation();

  const dispatch = useDispatch();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const composeModel = useSelector(selectComposeModel);
  const openComposeModel = useSelector(selectOpenComposeModel);
  const fullComposeModel = useSelector(selectFullComposeModel);
  const draftData = useSelector(selectDraftData);
  const defaultToMail = useSelector(selectDefaultToMail);

  const isEmailSavedToDraft = useSelector(selectedEmailSavedToDraft);
  const isDraftUpdate = useSelector(selectedIsDraftUpdateSelector);

  const [receiverCurrentValue, setReceiverCurrentValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { fnShowSnackBar } = useSnackBarManager();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    if ((draftData?.receiver?.length > 0) || draftData?.subject || draftData?.body) {
      setData(draftData);
    } else if(!draftData) {
      setData({ ...emailEmptyData, receiver: defaultToMail || [], });
    } else {
      setData({ ...data, receiver: defaultToMail || [], });
    }
  }, [draftData, defaultToMail]);

  const handleOpenComposeModel = (mail) => {
    setData(emailEmptyData);
    dispatch(setDraftData(null));
    if (mail?.length > 0) { handleSetMail(mail); }
    else { handleSetMail([]); }
    dispatch(setComposeModel(false));
    dispatch(setOpenComposeModel(false));
  };

  const handleSetMail = (data) => {
    dispatch(setDefaultToMail(data));
  };

  const handleCLose = () => {
    dispatch(setComposeModel(true));
    dispatch(setOpenComposeModel(false));
    dispatch(setIsEmailSavedToDraft(true));
    setIsLoading(false);
    setData(emailEmptyData);
    if (isDraftUpdate) { dispatch(setIsDraftUpdate(false)) };
  };

  const handleDraftOpenComposeModel = async (receiveData, isDraftChanges) => {
      if(draftData?.id == receiveData?.id) {
        handleCancel(isDraftChanges);
        dispatch(setDraftData(null));
      } else {

        dispatch(setComposeModel(false));
        dispatch(setDraftData(receiveData));
        setData(receiveData);

        if(draftData?.id != receiveData?.id && isDraftUpdate) {
          fnUpdateDraft(draftData);
        }
      }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
    dispatch(setDraftData({ ...data, ...draftData, [name]: value }));
    console.log('draftData', draftData);
    if (!isDraftUpdate) {
      dispatch(setIsDraftUpdate(true))
    }
  };

  const handleCancel = async (isDraftChanges) => {
    if (isDraftChanges || isEmailSavedToDraft) { handleDraft(); }

    if (isDraftUpdate) { fnUpdateDraft(draftData); }
    else { dispatch(setDraftData(null)) }

    handleCLose();
  };

  const fnAfterMailSent = async (draftId) => {
    if (draftId) {
      if (!isFetchingDraftMails) {
        setData(emailEmptyData);
        fnShowSnackBar("Email has been sent successfully");
        handleCLose();
      }
    } else {
      setData(emailEmptyData);
      fnShowSnackBar("Email has been sent successfully");
      handleCLose();
    }
  };

  const fnUpdateDraft = async (currentDraftData) => {
    if (currentDraftData?.id) {
      const { receiver, subject, body } = currentDraftData;
      const modifyData = {
        ...currentDraftData,
        receiver: receiver,
        attachments: selectedFiles.length > 0 ? selectedFiles : [],
      };
      if (body || subject || receiver?.length > 0) {
        try {
          await updateDraft(modifyData).unwrap();
          dispatch(setIsDraftUpdate(false));
          dispatch(setDraftData(null));
        } catch (error) {
          dispatch(setDraftData(null));
        }
      }
    }
    return false;
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("subject", data.subject);
    formData.append("body", data.body);
    data?.receiver?.forEach((email) => { formData.append("to[]", email); });
    selectedFiles?.forEach((file, index) => {
      formData.append(`attachments[${index}]`, file);
    });

    await sendMail(formData)
      .unwrap()
      .then(async (response) => {
        if (response.success) {
          if (draftData?.id) {
            const response = await deleteDraft(draftData?.id).unwrap();
            if (response?.success) { fnAfterMailSent(draftData?.id); }
          }
          else { fnAfterMailSent(); }
        }
        else {
          fnShowSnackBar("Something is wrong!", true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        fnShowSnackBar(error?.error || "Something is wrong!", true);
        setIsLoading(false);
      });
  };

  const handleDraft = async () => {
    const { receiver, subject, body } = data;
    // data?.receiver?.forEach((email) => { formData.append("to[]", email); });
   
    const modifyData = {
      ...data,
      receiver: receiver,
      attachments: selectedFiles.length > 0 ? selectedFiles : [],
    };
    if (body || subject || receiver?.length > 0) {
      try {
        addDraft(modifyData)
          .unwrap()
          .then((response) => {
            if (response?.success) {
              fnShowSnackBar(response?.message || "Email saved in Draft");
              dispatch(setDraftData(null));
            } else {
              fnShowSnackBar("Some thing is wrong!", true);
              dispatch(setDraftData(null));
            }
          });
      } catch (error) {
        fnShowSnackBar("Some thing is wrong!", true);
        console.error("An error occurred while saving:", error);
      }
    }
  };

  const handleAddReceiver = () => {
    if (emailRegex.test(receiverCurrentValue)) {
      setData((prevState) => ({
        ...prevState,
        receiver: [...prevState.receiver, receiverCurrentValue],
      }));
      setReceiverCurrentValue("");
    } else {
      fnShowSnackBar("Invalid Email!");
    }
  };

  const handleAddReceiverOnEnter = (e) => {
    if (e.code === "Enter") handleAddReceiver();
  };

  const handleAddReceiverOnBlur = () => {
    if (receiverCurrentValue) handleAddReceiver();
  };

  const removeFromList = (index) => {
    setData((prevState) => ({ ...prevState, receiver: prevState?.receiver?.filter((_, i) => i !== index), }));
  };

  const handleAttachFile = (e) => {
    setSelectedFiles([...selectedFiles, ...e.target.files]);
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
  };

  return {
    handleAddReceiver,
    handleAddReceiverOnEnter,
    handleAddReceiverOnBlur,
    removeFromList,
    handleAttachFile,
    handleRemoveFile,
    setReceiverCurrentValue,
    setIsDraftUpdate,

    isDraftUpdate,
    receiverCurrentValue,
    selectedFiles,
    defaultToMail,
    isLoadingSendMail,
    isLoading,
    composeModel,
    openComposeModel,
    fullComposeModel,
    setComposeModel,
    setFullComposeModel,
    setOpenComposeModel,
    data,

    handleSetMail,
    handleOpenComposeModel,
    handleDraftOpenComposeModel,
    handleInputChange,
    handleCancel,
    handleSubmit,
    setData,
    handleDraft,
    handleCLose,
  };
}

export default useComposeModel;
