import React from 'react'

const DZModal = ({children, modalRef, className}) => {
  return (
    <div className='fixed flex items-center justify-center bg-[#112D4E20] inset-0 z-10'>
    <div
      ref={modalRef}
      className={`z-[10] bg-F9F7F7 flex flex-col w-[420px] max-h-[90vh] max-sm:w-[330px] items-center justify-start gap-[16px] p-[24px] rounded-[12px]
        max-lg:max-h-[90vh] max-lg:overflow-y-auto
        max-xl:max-h-[85vh] max-xl:overflow-y-auto
        overflow-y-auto ${className}`}
    >
      {children}
    </div>
  </div>
  
  )
}

export default DZModal