import { Outlet } from "react-router-dom";
import Sidebar from "../components/shared/sidebar/Sidebar";
import Navbar from "../components/shared/navbar/Navbar";
import SideBarLinks from "../constants/SideBarLinks";
import OperationBar from "../components/opreationBar/OperationBar";
import { DragDropContext } from "react-beautiful-dnd";
import useFileFolderActions from "../lib/useFileFolderActions";
import { SIDE_BAR_TYPES } from "../constants/Index";
function DriveAppLayout() {
  const { handleDragEnd } = useFileFolderActions();
  return (
    <div className="flex h-full overflow-hidden">
      <DragDropContext onDragEnd={handleDragEnd}>
        <Sidebar
          sideBarLinks={SideBarLinks.juriiDriveLinksArray}
          sideBarType={SIDE_BAR_TYPES.drive}
        />
        <div className="flex flex-col max-h-full  w-full">
          <Navbar />
          <div className="flex flex-col gap-[20px] min-h-[100%] p-[16px] max-h-full">
            <OperationBar  type={SIDE_BAR_TYPES.drive} />
            <Outlet /> 
          </div>
        </div>
      </DragDropContext>
    </div>
  );
}

export default DriveAppLayout;